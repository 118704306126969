import axios from "axios";
import baseUrl from "./baseUrl";
let token = JSON.parse(sessionStorage.getItem('userInfo')).access_token
export function ossUpload (file,number) {
  return new Promise((resolve, reject) => {
    let folder = "";
    if(number){
      folder = "model/"
    }else{
      folder = "log/"
    }
    let nameArr = file.name.split(".");
    let name = nameArr[nameArr.length - 1]
    let filename = file.name;
    axios.post(baseUrl + "common/Aliyun/upload",{folder},{headers:{Authorization:token}}).then(res=>{
      return res.data;
    }).then((res) => {
      const { host, accessid, policy, signature,dir } = res;
      const formData = new FormData();
      const time = new Date().getTime();
      const randomNum = (Math.random()*459872).toFixed(0);
      const key = dir + time + randomNum + "." + name;
      formData.append('key', key);
      formData.append('OSSAccessKeyId', accessid);
      formData.append('policy', policy);
      formData.append('signature', signature);
      formData.append('success_action_status', '200');
      formData.append('file', file);
      axios.post(host, formData,{headers:{Accept:"*/*"}}).then((res) => {
        const { status } = res;
        if (status === 200) {
          const data = {
            url: `${host}/${key}`,
            name:filename
          }
          resolve(data);
        } else {
          reject(res)
        }
      }).catch(err => {
        reject(err)
      })
    }).catch(err => {})
  })
}
