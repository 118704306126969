<template>
  <div>
    <div class="flexRow justify-content-between">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">项目列表</el-breadcrumb-item>
        <el-breadcrumb-item>项目日志</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button type="primary" @click="openBox(1)">新建日志</el-button>
      </div>
    </div>
    <div class="marginTop">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="num" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="ltn" label="日志类型" align="center"></el-table-column>
        <el-table-column prop="desc" label="日志描述" align="center"></el-table-column>
        <el-table-column label="文件" align="center">
          <template slot-scope="scope" v-if="scope.row.files && scope.row.files.length">
            <div class="flexRow justify-content-center flexWrap">
              <span class="fileBtn" @click="openUrl(item.url)" :title="item.name" v-for="(item,index) in scope.row.files" :key="index">
                {{item.name1}}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="user" label="创建人" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span class="editBtn" @click="openBox(2,scope.$index)">编辑</span>
            <span class="editBtn" @click="delItem(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page flexRow justify-content-center">
        <el-pagination
            background
            layout="total,prev, pager, next,jumper"
            :current-page="curPage"
            :total="totalItem"
            :page-size="20"
            @current-change="toPages">
        </el-pagination>
      </div>
    </div>
    <!--  新建项目  -->
    <el-dialog :visible.sync="showBox" width="40%" :close-on-click-modal="false">
      <el-form ref="form" label-width="90px" v-loading="loading">
        <el-form-item required label="日志类型">
          <el-select v-model="formData.log_id" placeholder="请选择日志类型" style="width:100%">
            <el-option
                v-for="item in logTyeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日志描述">
          <el-input type="textarea" v-model="formData.desc" placeholder="请输入项目描述" clearable></el-input>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
              action=""
              multiple
              :show-file-list="false"
              :http-request="uploadFile">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div class="marginTop" v-if="files.length">
            <div class="tagBox" v-for="(tag,tagIndex) in files" :key="tagIndex">
              <el-tag closable type="success" @close="closeTag(tagIndex)">
                {{tag.name}}
              </el-tag>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="marginTop flexRow justify-content-end">
        <el-button type="primary" @click="subMsg">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {ossUpload} from "../utils/upload";

export default {
  name: "ProjectList",
  data(){
    return{
      tableData:[],
      totalItem:0,
      curPage:1,
      showBox:false,
      formData:{
        log_id:'',       //日志类型id
        desc:'',              //描述
      },
      files:[],
      logTyeArr:[],
      loading:false
    }
  },
  created(){
    this.$setMenuActive('/')
  },
  mounted(){
    let id = this.$route.query.id;
    this.pid = id;
    this.p = 1;
    let params = {
      p:1,
      project_id:id
    }
    this.getList(params);
    this.getLogTypeList()
  },
  methods:{
    //获取日志类型
    async getLogTypeList(){
      let res = await this.mainFun('projectLogType/ajaxSearch');
      this.logTyeArr = res.data;
    },
    //清除信息
    clearInfo(){
      this.formData = {
        log_id:'',
        desc:'',
      }
      this.files = []
    },
    //获取列表
    async getList(params){
      let res = await this.mainFun('ProjectLog/getList',params);
      //console.log(res)
      this.totalItem = res.data.total;
      let data = res.data.data;
      data.map((item,i)=>{
        let num = (res.data.current_page - 1) * res.data.per_page + parseInt(i) + 1;
        item.num = num;
        if(item.files) {
          item.files = JSON.parse(item.files);
          item.files.map(subItem=>{
            console.log(subItem)
            if(subItem.name.length > 10){
              subItem.name1 = subItem.name.slice(0,10)
            }else{
              subItem.name1 = subItem.name;
            }
            return subItem
          })
        }
        return item
      })
      this.tableData = data;
    },
    //分页
    toPages(p){
      this.p = p;
      this.curPage = p;
      this.getList({p,project_id:this.pid})
    },
    //上传文件
    async uploadFile(file){
      this.loading = true;
      let res = await ossUpload(file.file);
      this.files.push(res)
      this.loading = false;
    },
    //移除文件
    closeTag(index){
      console.log(index)
      this.files.splice(index,1)
    },
    //查看文件
    openUrl(url){
      window.open(url)
    },
    //打开新建or编辑弹窗
    openBox(type,index){
      if(type === 1){
        this.eid = '';
        this.clearInfo()
      }else{
        let item = this.tableData[index];
        this.eid = item.id;
        this.formData = {
          log_id:item.project_log_type_id,
          desc:item.desc,
        }
        if(item.files && item.files.length){
          this.files = item.files;
        }
      }
      this.showBox = true;
    },
    //提交信息
    async subMsg(){
      let {desc,log_id} = this.formData;
      if(log_id == ''){
        this.$message.warning('请选择日志类型')
        return
      }
      let files = '';
      if(this.files.length){
        files = JSON.stringify(this.files)
      }
      let params = {
        desc,
        project_log_type_id: log_id,
        project_id: this.pid,
        files
      }
      let url = 'ProjectLog/'
      if(this.eid){
        params.id = this.eid;
        url += 'edit'
      }else{
        url += 'add'
      }
      let res = await this.mainFun(url,params);
      if(res.data.code == 1){
        this.$message.success(res.data.message);
        let p = 1;
        if(params.id){
          p = this.p;
        }
        let newParams = {p,project_id:this.pid}
        await this.getList(newParams)
        this.showBox = false;
      }else{
        this.$message.error(res.data.message);
      }
    },
    //删除当前信息
    delItem(id){
      this.$confirm('是否确认删除当前信息','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning',
      }).then(async ()=>{
        let res = await this.mainFun('ProjectLog/del',{id});
        if(res.data.code === 1){
          this.$message.success(res.data.message);
          let newParams = {p:this.p,project_id:this.pid}
          await this.getList(newParams)
        }
      }).catch(()=>{})
    },
  }
}
</script>

<style scoped>
.fileBtn{
  background: #409EFF;
  color:#fff;
  font-size:0.0625rem;
  padding: 0.036458rem 0.078125rem;
  cursor: pointer;
  line-height: 1;
  border-radius: 5px;
}
</style>